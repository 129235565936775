import React, { useState, useEffect } from "react";
import "../design/dashboard.css";
import Navbar from "./Navbar";
import Cookies from "js-cookie";
import { api, fileApi } from "../CONSTANTS";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause } from "@fortawesome/free-solid-svg-icons";

const Dash = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [dashboardData, setDashboardData] = useState([]);
  const [blobData, setBlobData] = useState([]);
  const [VmData, setVmData] = useState([]);
  const handleRedirect = () => {
    navigate("/tutorial");
  };
  useEffect(() => {
    // Check if the user is already logged in
    if (!Cookies.get("isLoggedIn")) {
      navigate("/login");
    } else {
      const name = Cookies.get("name"); // Get the 'name' cookie
      if (name) {
        setUsername(name); // Set the username if the cookie exists
      }
    }
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${api}getDashData`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ user_id: Cookies.get("id") }),
        });
        const result = await response.json();
        setDashboardData(result.msg);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await await fetch(`${api}get_resource_files`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ user_id: Cookies.get("id") }),
        });
        const result = await response.json();
        setBlobData(result.message);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${api}get_vm_details`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ user_id: Cookies.get("id") }),
        });
        
        const result = await response.json();
        console.log(result.message);
        setVmData(result.message);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const downloadCert = async (service_id) => {
    try {
      const response = await fetch(`${api}generate_certificate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: Cookies.get("id"),
          service_id: service_id,
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to fetch file path");
      }
      const data = await response.json();
      const filePath = `${fileApi}${data.message}`;

      const fileResponse = await fetch(filePath);
      const blob = await fileResponse.blob();

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = data.message.split("/").pop();
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const homepage = () => {
    navigate('/home')
};

  return (
    <div className="entirebody">
      <div className="navbar1">
        <div className="logo">
          <img onClick={homepage} src="/assets/etcnew-removebg-preview 1.png" alt="" />
        </div>
        <div>
          <Navbar />
        </div>
      </div>
      <div className="upperbody">
        <h1>Welcome, {username || "Username"}</h1>
        <p>Start Your Learning Journey From Today</p>
      </div>
      <div className="lowerbody">
        <div className="lowerbody1">
          <div className="lowerbody11">
            <video autoPlay loop muted>
              <source src="/assets/background.mp4" />
            </video>
          </div>
          <div className="lowerbody12">
            <table id="customers">
              <thead>
                <tr>
                  <th>Purchase</th>
                  <th>Start date</th>
                  <th>Duration</th>
                  <th>Hours Used</th>
                  <th>End date</th>
                  <th>Status</th>
                  <th>Certificate</th>
                </tr>
              </thead>
              <tbody>
                {dashboardData.length > 0 ? (
                  dashboardData.map((data, index) => (
                    <tr key={index}>
                      <td>{data.service_name}</td>
                      <td>{new Date(data.start).toLocaleString()}</td>
                      <td>{data.duration}</td>
                      <td>{data.active_hours}</td>
                      <td>{new Date(data.expiry).toLocaleString()}</td>
                      <td>
                        {data.active ? "Active" : <button>Buy Again</button>}
                      </td>
                      <td>
                        {data.active ? (
                          "Course Not Complete"
                        ) : (
                          <button
                            onClick={() => {
                              downloadCert(data.user_service_id);
                            }}
                          >
                            Download
                          </button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="1" style={{ textAlign: "center" }}>
                      No data available
                    </td>
                    <td colSpan="1" style={{ textAlign: "center" }}>
                      No data available
                    </td>
                    <td colSpan="1" style={{ textAlign: "center" }}>
                      No data available
                    </td>
                    <td colSpan="1" style={{ textAlign: "center" }}>
                      No data available
                    </td>
                    <td colSpan="1" style={{ textAlign: "center" }}>
                      No data available
                    </td>
                    <td colSpan="1" style={{ textAlign: "center" }}>
                      No data available
                    </td>
                    <td colSpan="1" style={{ textAlign: "center" }}>
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="lowerbody131">
            <p className="lowerbodytext">tutorial</p>
            <p className="lowerbodysubtext">
              follow the tutorial for the best guidance
            </p>
            <div className="lowerbody13">
              <div className="box1">
                <h1>AZURE</h1>
                <button onClick={handleRedirect}>Go To Tutorial</button>
              </div>
              <div className="box1">
                <h1>AWS</h1>
                <button onClick={handleRedirect}>Go To Tutorial</button>
              </div>
              <div className="box1">
                <h1>GCP</h1>
                <button onClick={handleRedirect}>Go To Tutorial</button>
                <div>
                  <br></br>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="lowerbody2">
                 <h2>Important Instruction</h2>
               <li>First check if you have a "Microsoft Account" in your registered email</li>
                 <li>If "YES" continue with you mail-id OR, before buying any service, please create a "Microsoft Account" using this email. <a
                        href="https://signup.live.com/signup?sru=https%3a%2f%2flogin.live.com%2foauth20_authorize.srf%3flc%3d1033%26client_id%3d81feaced-5ddd-41e7-8bef-3e20a2689bb7%26mkt%3dEN-US%26opid%3d346BF89B6971C51F%26opidt%3d1731308835%26uaid%3d9c1c3df029aa43f8a2679b6097309bb9%26contextid%3dC1A7F3CE488E27AA%26opignore%3d1&mkt=EN-US&uiflavor=web&lw=1&fl=easi2&client_id=81feaced-5ddd-41e7-8bef-3e20a2689bb7&uaid=9c1c3df029aa43f8a2679b6097309bb9&suc=81feaced-5ddd-41e7-8bef-3e20a2689bb7&lic=1"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ fontWeight: 'bold', color:'red'}}
                    >
                        click here
                    </a> to create a "Microsoft Account"</li>
                <li>After successful payment, you will get an invitation link in your email within 24 hours</li>
                <li>After accepting that invitation, open <a style={{ fontWeight: 'bold', color:'green'}} href="https://portal.azure.com/">portal.azure.com</a> in a new tab</li>
                <li>Now follow our <a style={{ color:'green'}}href="/tutorial">tutorial</a> page for detailed step-by-step instrustions</li>
            </div> */}
            <div className="lowerbody2">
    <h2>Important Instruction</h2>
    <div className="movingText">
        <span>Please ensure to stop your virtual machine at the end of each day's tasks to avoid unnecessary consumption of resources and deduction from your allocated timeline</span>
    </div>
    <ul>
        <li>First check if you have a "Microsoft Account" in your registered email</li>
        <li>
            If "YES" continue with your mail-id OR, before buying any service, please create a "Microsoft Account" using this email. 
            <a
                href="https://signup.live.com/signup?sru=https%3a%2f%2flogin.live.com%2foauth20_authorize.srf%3flc%3d1033%26client_id%3d81feaced-5ddd-41e7-8bef-3e20a2689bb7%26mkt%3dEN-US%26opid%3d346BF89B6971C51F%26opidt%3d1731308835%26uaid%3d9c1c3df029aa43f8a2679b6097309bb9%26contextid%3dC1A7F3CE488E27AA%26opignore%3d1&mkt=EN-US&uiflavor=web&lw=1&fl=easi2&client_id=81feaced-5ddd-41e7-8bef-3e20a2689bb7&uaid=9c1c3df029aa43f8a2679b6097309bb9&suc=81feaced-5ddd-41e7-8bef-3e20a2689bb7&lic=1"
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontWeight: 'bold', color: 'red' }}
            >
                click here
            </a> 
            to create a "Microsoft Account"
        </li>
        <li>After successful payment, you will get an invitation link in your email within 24 hours</li>
        <li>
            After accepting that invitation, open 
            <a style={{ fontWeight: 'bold', color: 'green' }} href="https://portal.azure.com/">portal.azure.com</a> 
            in a new tab
        </li>
        <li>Now follow our <a style={{ color: 'green' }} href="/tutorial">tutorial</a> page for detailed step-by-step instructions</li>
    </ul>
</div>


      </div>
      <div className="lastlowerbody">
        <div className="azure_blob_storage">
        <div className="div1">
          <p>Azure Blob Storage</p>
          
        </div>

        <div className="table_section">
          <table style={{ borderSpacing: "0" }} id="customers">
            <thead>
              <tr>
                <th style={{ color: "orange" }}>Storage Name</th>
                <th style={{ color: "orange" }}>Container Name</th>
                <th style={{ color: "orange" }}>Active hours</th>
                <th style={{ color: "orange" }}>Creation Date</th>
                <th style={{ color: "orange" }}>No. of Blobs</th>
                <th style={{ color: "orange" }}>Size</th>
              </tr>
            </thead>

            <tbody style={{textAlign:"center"}}>
            {blobData.map((item, index) => (
                    <tr key={index}>
                        <td>{item.blob}</td>
                        <td>{item.container_name}</td>
                        <td>{item.active_hours}</td>
                        <td>
                            {item.created_date
                                ? new Date(item.created_date).toLocaleString()
                                : 'N/A'}
                        </td>
                        <td>{item.blob_count}</td>
                        <td>{item.blob_size} MB</td>
                    </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
        <div>
          <br></br>
          <br></br>
        </div>
      </div>

      <div className="lastlowerbody">
        <div className="azure_blob_storage">
        <div className="div1">
          <p>Azure Virtual Machine</p>
          
        </div>

        <div className="table_section">
          <table style={{ borderSpacing: "0" }} id="customers">
            <thead>
              <tr>
                <th style={{ color: "orange" }}>Virtual Machine Name</th>
                <th style={{ color: "orange" }}>Os Name</th>
                <th style={{ color: "orange" }}>Os Size</th>
                <th style={{ color: "orange" }}>Size</th>
                <th style={{ color: "orange" }}>Last Active Date</th>
                <th style={{ color: "orange" }}>Last Active Time</th>
              </tr>
            </thead>

            <tbody style={{textAlign:"center"}}>
                {VmData.map((vm, index) => (
                    <tr key={index}>
                        <td>{vm.vm_name}</td>
                        <td>{vm.os}</td>
                        <td>{vm.os_disk}</td>
                        <td>{vm.vm_size}</td>
                        <td>{vm.Date}</td>
                        {/* <td>{new Date(vm.created_date).toLocaleString()}</td> */}
                        <td>{vm.Time}</td>
                    </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
        <div>
          <br></br>
          <br></br>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Dash;


