import React, { useState, useEffect } from "react";
import '../design/payment.css';
import Cookies from 'js-cookie'; 
import { api } from '../CONSTANTS'
import { Link, useNavigate } from 'react-router-dom';
import NewBot from "./NewBot";
const Payment = () => {
    const [paymentMethod, setPaymentMethod] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        transactionId: '',
        amountID: '',
        user_id: Cookies.get("id"),
        service_id: Cookies.get("serviceID"),
    });
    const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is already logged in
        if (Cookies.get('isLoggedIn')) {
            // alert(Cookies.get("id"));
            // alert(Cookies.get("serviceID"));
        }
    }, []);

    const handlePaymentChange = (e) => {
        setPaymentMethod(e.target.value);
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [id]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);
        try {
            const response = await fetch(`${api}payment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            console.log(result); // Handle success response
            setShowPopup(true); // Show success popup

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    const handlePopupClose = () => {
        setShowPopup(false); // Close the popup
        navigate('/home'); // Redirect to the home page
    };

    return (
        <div className="bodyP">
            <div className="payment-pageP">
                <h2>Payment Information</h2>
                <p style={{ color: 'red' }}>
                    Please make sure you have a Microsoft account in the email ID entered below.{' '}
                    <a
                        href="https://signup.live.com/signup?sru=https%3a%2f%2flogin.live.com%2foauth20_authorize.srf%3flc%3d1033%26client_id%3d81feaced-5ddd-41e7-8bef-3e20a2689bb7%26mkt%3dEN-US%26opid%3d346BF89B6971C51F%26opidt%3d1731308835%26uaid%3d9c1c3df029aa43f8a2679b6097309bb9%26contextid%3dC1A7F3CE488E27AA%26opignore%3d1&mkt=EN-US&uiflavor=web&lw=1&fl=easi2&client_id=81feaced-5ddd-41e7-8bef-3e20a2689bb7&uaid=9c1c3df029aa43f8a2679b6097309bb9&suc=81feaced-5ddd-41e7-8bef-3e20a2689bb7&lic=1"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Click here to make it
                    </a>
                </p>

                <form onSubmit={handleSubmit}>
                    <div className="form-groupP">
                        <label htmlFor="name">
                            Name: <span className="required">*</span>
                        </label>
                        <input type="text" id="name" placeholder="Enter your full name" onChange={handleInputChange} required />
                    </div>
                    <div className="form-groupP">
                        <label htmlFor="email">
                            Email: <span className="required">*</span>
                        </label>
                        <input type="email" id="email" placeholder="Enter your email address" onChange={handleInputChange} required />
                    </div>
                    <div className="form-groupP">
                        <label htmlFor="paymentMethod">Payment Method:</label>
                        <select id="paymentMethod" onChange={handlePaymentChange} value={paymentMethod}>
                            <option value="" selected disabled> --- Select Payment Method ---</option>
                            <option value="UPI">UPI</option>
                            <option value="DebitCard" disabled>Debit Card</option>
                        </select>
                    </div>

                    {/* UPI Payment Fields */}
                    {paymentMethod === 'UPI' && (
                        <div className="upi-detailsP">
                            <h3>UPI Payment Details</h3>
                            <div className="qr">
                                <img src="/assets/amount.jpg" alt="" />
                            </div>
                            <div className="form-groupP">
                                <label htmlFor="amountID">
                                    Amount Paid: <span className="required">*</span>
                                </label>
                                <input type="number" id="amountID" placeholder="Enter amount paid" onChange={handleInputChange} required />
                            </div>
                            <div className="form-groupP">
                                <label htmlFor="transactionId">
                                    Transaction ID: <span className="required">*</span>
                                </label>
                                <input type="text" id="transactionId" placeholder="Enter your Transaction ID" onChange={handleInputChange} required />
                            </div>
                        </div>
                    )}

                    {/* Debit Card Payment Fields */}
                    {paymentMethod === 'DebitCard' && (
                        <div className="debit-card-detailsP">
                            <h3>Debit Card Details</h3>
                            <div className="form-groupP">
                                <input type="text" id="cardNumber" placeholder="Enter your card number" onChange={handleInputChange} />
                            </div>
                            <div className="expiry-containerP">
                                <div className="form-groupP">
                                    <input type="text" id="expiryMonth" placeholder="MM" maxLength="2" onChange={handleInputChange} />
                                </div>
                                <div className="form-groupP">
                                    <input type="text" id="expiryYear" placeholder="YY" maxLength="2" onChange={handleInputChange} />
                                </div>
                            </div>
                            <div className="form-groupP">
                                <input type="text" id="cardHolderName" placeholder="Enter card holder name" onChange={handleInputChange} />
                            </div>
                            <div className="form-groupP">
                                <input type="text" id="securityCode" placeholder="Enter CVV" maxLength="3" onChange={handleInputChange} />
                            </div>
                        </div>
                    )}

                    <button type="submit" className="submit-btnP">Pay Now</button>
                </form>
            </div>
            <div>
                <NewBot />
            </div>
            {/* Popup for successful payment */}
            {showPopup && (
                <div className="popup">
                    <div className="popup-content">
                        <h3>Payment Successful!</h3>
                        <p>Your payment was processed successfully. You will be get one inivation email within 24 hours</p>
                        <button onClick={handlePopupClose}>OK</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Payment;
