import React from 'react';
import '../design/NewBot.css'

const NewBot=()=> {
    const openWhatsApp = () => {
        const phoneNumber = "7864850872"; // Replace with your desired phone number
        const message = "Hello"; // Replace with your custom message
      
        // WhatsApp URL for app
        const url = `whatsapp://send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
      
        console.log(url); // Debug the generated URL
      
        // Attempt to open WhatsApp directly
        window.location.href = url; // This tries to open the app directly
      };

      return (
        <div className="App">
          
          <button className="floating-button" onClick={openWhatsApp}>
          <img src="/assets/chat.png" alt="" />
          </button>
        </div>
      );
}

export default NewBot;
